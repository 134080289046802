<template>
  <div>
    <div class="head">
      <div class="content_select">
        <div>
          <el-button icon="RefreshRight" size="small" style="margin-right: 5px;" @click="getList()"></el-button>
          <qzf-search-period
            v-model:period="listQuery.period"
            :clearable="false"
            style="width:100px"
            @success="getList"
          ></qzf-search-period>
          <el-input size="small" placeholder="请输入公司名称/报税手机号/编号" v-model.trim="listQuery.name" style="width:200px;margin-left: 5px;" @keyup.enter="getList" clearable></el-input>
          <el-button size="small" type="primary" @click="getList" style="margin-right:10px">
              <el-icon><Search /></el-icon><span  > 搜索</span>
          </el-button>
          <search @success="getList" @cancel="cancel">
              <el-form style="width:380px" label-width="110px" :inline="true" class="styleForm" size="small">
                <el-form-item label="培训老师：">   
                  <el-input size="small" placeholder="请输入培训老师" v-model.trim="listQuery.trainingTeacher" style="width:200px;margin-left: 5px;" clearable></el-input>
                </el-form-item>
                <el-form-item label="地区："> 
                  <selectcity v-model:citys="listQuery.address" class="w-130" style="width:200px;margin-left: 5px;"></selectcity>
                </el-form-item>
              </el-form>
          </search>
        </div>
        <div>
          <el-button
            type="primary"
            plain
            size="small"
            icon="Refresh"
            @click="freshList"
            >刷新列表</el-button
          >
        </div>
      </div>
      <!-- <span style="margin-left: 5px;"
        ><el-switch
          v-model="listQuery.date"
          active-text="超2月未做账"
          inactive-text=""
          @change="getList"
          size="small"
        ></el-switch
      ></span> -->
    </div>
    <div class="content">
      <el-table
        :data="tableData"
        border
        style="width: 100%"
        v-loading="loading"
        :height="contentStyleObj"
        stripe
        @selection-change="handleSelectionChange"
      >
        <template #empty>
          <el-empty :image-size="150" description="没有数据"></el-empty>
        </template>
        <el-table-column type="selection" width="55" align="center" fixed />
        <el-table-column type="index" label="序号" width="55" align="center" fixed/>
        <el-table-column prop="name" label="公司名称" width="155" align="center" fixed/>
        <el-table-column prop="address" label="地区" width="100" align="center" />
        <el-table-column prop="remark" label="备注" width="160" align="center">
          <template #default="scope">
            <div style="display: flex;">
              <div style="width: 95%;">
                <span>{{scope.row?.remark}}</span>
              </div>
              <div>
                <el-popover v-model:visible="scope.row.visible4" placement="bottom" :width="350" trigger="click">
                  <el-input
                    v-model="remark"
                    :rows="4"
                    type="textarea"
                    placeholder="请输入"
                  />
                  <div class="opera-btn-box">
                    <el-button size="small" type="primary" @click="editNote(scope.row,'4')">确定</el-button>
                  </div>
                  <template #reference>
                    <el-icon style="text-align: right;" @click="smallNotes('4')"><Edit /></el-icon>
                    <!-- <span class="xgmStyle" @click="smallNotes(scope.row?.id)">记</span> -->
                  </template>
                </el-popover>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="totalScore" label="综合评分" width="120" align="center" sortable>
          <template #default="scope">
            <div>{{ totalScore(scope.row) }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="replyScore" label="群消息回复评分" width="140" align="center" sortable>
          <template #default="scope">
            <div style="display: flex;">
              <div style="width: 95%;">
                <span>{{scope.row?.replyScore}}</span>
              </div>
              <div>
                <el-popover v-model:visible="scope.row.visible1" placement="bottom" :width="350" trigger="click">
                  <div class="inputs">
                    <el-input-number
                      v-model="replyScore"
                      :min="0"
                      :max="10"
                      controls-position="right"
                      size="small"
                      style="width: 60%;"
                      placeholder="请评分"
                    />
                  </div>
                  <div class="opera-btn-box">
                    <el-button size="small" type="primary" @click="editNote(scope.row,'1')">确定</el-button>
                  </div>
                  <template #reference>
                    <el-icon style="text-align: right;" @click="smallNotes('1')"><Edit /></el-icon>
                    <!-- <span class="xgmStyle" @click="smallNotes(scope.row?.id)">记</span> -->
                  </template>
                </el-popover>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="complaintScore" label="有无投诉评分" width="120" align="center" sortable>
          <template #default="scope">
            <div style="display: flex;">
              <div style="width: 95%;">
                <span>{{scope.row?.complaintScore}}</span>
              </div>
              <div>
                <el-popover v-model:visible="scope.row.visible2" placement="bottom" :width="350" trigger="click">
                  <div class="inputs">
                    <el-input-number
                      v-model="complaintScore"
                      :min="0"
                      :max="10"
                      controls-position="right"
                      size="small"
                      style="width: 60%;"
                      placeholder="请评分"
                    />
                  </div>
                  <div class="opera-btn-box">
                    <el-button size="small" type="primary" @click="editNote(scope.row,'2')">确定</el-button>
                  </div>
                  <template #reference>
                    <el-icon style="text-align: right;" @click="smallNotes('2')"><Edit /></el-icon>
                    <!-- <span class="xgmStyle" @click="smallNotes(scope.row?.id)">记</span> -->
                  </template>
                </el-popover>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="increaseScore" label="有无续增评分" width="120" align="center" sortable>
          <template #default="scope">
            <div style="display: flex;">
              <div style="width: 95%;">
                <span>{{scope.row?.increaseScore}}</span>
              </div>
              <div>
                <el-popover v-model:visible="scope.row.visible3" placement="bottom" :width="350" trigger="click">
                  <div class="inputs">
                    <el-input-number
                      v-model="increaseScore"
                      :min="0"
                      :max="10"
                      controls-position="right"
                      size="small"
                      style="width: 60%;"
                      placeholder="请评分"
                    />
                  </div>
                  <div class="opera-btn-box">
                    <el-button size="small" type="primary" @click="editNote(scope.row,'3')">确定</el-button>
                  </div>
                  <template #reference>
                    <el-icon style="text-align: right;" @click="smallNotes('3')"><Edit /></el-icon>
                    <!-- <span class="xgmStyle" @click="smallNotes(scope.row?.id)">记</span> -->
                  </template>
                </el-popover>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="usedCountScore" label="使用用户评分" width="120" align="center" sortable/>
        <el-table-column prop="periodCheckoutScore" label="本期结账数评分" width="140" align="center" sortable/>
        <el-table-column prop="survivalCountScore" label="保活户数评分" width="120" align="center" sortable/>
        <el-table-column prop="gsCountScore" label="个税申报数评分" width="140" align="center" sortable/>
        <el-table-column prop="gsSuccessScore" label="个税成功率评分" width="140" align="center" sortable/>
        <el-table-column prop="taxCountScore" label="税局申报数评分" width="140" align="center" sortable/>
        <el-table-column prop="taxSuccessScore" label="申报成功率评分" width="140" align="center" sortable/>
        <el-table-column prop="count" label="签约账套数" width="100" align="center" />
        <el-table-column prop="usedCount" label="已使用用户数" width="100" align="center" />
        <el-table-column prop="checkoutCount" label="结账户数" width="100" align="center" />
        <el-table-column prop="survivalCount" label="保活户数" width="100" align="center" />
        <el-table-column prop="gsCount" label="个税申报数" width="100" align="center" />
        <el-table-column prop="gsSuccessCount" label="个税成功数" width="100" align="center" />
        <el-table-column prop="taxCount" label="税局申报数" width="100" align="center" />
        <el-table-column prop="taxSuccessCount" label="税局成功数" width="100" align="center" />
        <el-table-column prop="trainingTeacher" label="培训老师" width="100" align="center" />
        <el-table-column prop="saleName" label="销售经理" width="100" align="center" />
      </el-table>
    </div>
    <div class="pagination">
      <qzf-pagination
        v-show="total > 0"
        :total="total"
        v-model:page="listQuery.page"
        v-model:limit="listQuery.limit"
        @pagination="getList()"
      />
    </div>
  </div>
</template>
<script>
import { currentAccountPeriod } from "@/utils";
import { comprehensiveList,updateComprehensive,refreshComprehensive } from "@/api/maintain";
import selectcity from "@/components/Screening/selectcity";

export default {
name: 'comprehensiveScore',
components:{
  selectcity,
},

mounted () {
},
data() {
  return {
    listQuery: {
      page: 1,
      limit: 20,
      period: currentAccountPeriod(),
      name:'',
      trainingTeacher:'',
      address:'',
    },
    tableData: [],
    loading: false,
    total: 0,
    increaseScore:null,
    complaintScore:null,
    replyScore:null,
    remark:'',
    visible2:false,
    visible1:false,
    visible3:false,
    visible4:false,
    dialogConfirm:false,
  }
},
computed: {
  totalScore() {
    return (row) => {
      const replyScore = row.replyScore || 0;
      const complaintScore = row.complaintScore || 0;
      const increaseScore = row.increaseScore || 0;
      const usedCountScore = row.usedCountScore || 0;
      const periodCheckoutScore = row.periodCheckoutScore || 0;
      const survivalCountScore = row.survivalCountScore || 0;
      const gsCountScore = row.gsCountScore || 0;
      const gsSuccessScore = row.gsSuccessScore || 0;
      const taxCountScore = row.taxCountScore || 0;
      const taxSuccessScore = row.taxSuccessScore || 0;
      
      return (
        replyScore +
        complaintScore +
        increaseScore +
        usedCountScore +
        periodCheckoutScore +
        survivalCountScore +
        gsCountScore +
        gsSuccessScore +
        taxCountScore +
        taxSuccessScore
      ).toFixed(2);
    };
  }
  },
created () {
  this.contentStyleObj = this.$getHeight(280);
  this.getList()
},
methods: {
  // 重置
  cancel(){
  let originLimit = this.listQuery.limit
  this.listQuery = {
    page: 1,
    limit: originLimit,
    period:currentAccountPeriod(),
    name:'',
    trainingTeacher:'',
    address:'',
  }
  this.$nextTick(()=>{
    this.getList()
  })
  },
  getList(){
    this.loading = true
    comprehensiveList(this.listQuery).then((res) => {
      if (res.data.msg == "success") {
        this.loading = false
        this.tableData = res.data.data.list;
        this.total = res.data.data.total;
      }
    });
  },
  freshList(){
    this.loading = true
    refreshComprehensive(this.listQuery).then((res) => {
      if (res.data.msg == "success") {
        this.getList()
      }
    });
  },
  editNote(row,val){
    let pamrs = row
    this.dialogConfirm = true
    if(val == '1'){
      pamrs.replyScore  = this.replyScore
    }else if(val == '2'){
      pamrs.complaintScore = this.complaintScore
    }else if(val == '3'){
      pamrs.increaseScore = this.increaseScore
    }else{
      pamrs.remark = this.remark
    }
    updateComprehensive(pamrs).then((res) => {
      if (res.data.msg == "success") {
        if(val == '1'){
          row.visible1 = false
        }else if(val == '2'){
          row.visible2 = false
        }else if(val == '3'){
          row.visible3 = false
        }else{
          row.visible4 = false
        }
        this.getList()

      }
    });
  },
  smallNotes(val){
    this.dialogConfirm = false
    if(val == '1'){
      this.visible1=true
    }else if(val == '2'){
      this.visible2=true
    }else if(val == '3'){
      this.visible3=true
    }else{
      this.visible4=true
    }
  },
},
}
</script>
<style lang="scss" scoped>
.head{
  padding: 10px;
}
.content{
  padding: 0 10px;
}
.opera-btn-box {
  text-align: right!important; 
  margin-top: 10px
}
.content_select {
  margin: 10px 0;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
<style>
.inputs{
  .el-input-number .el-input__inner{
    text-align: left!important;
  }
}
</style>